// @flow
import React, {type Element} from "react";
import {graphql} from "gatsby";
import {type ContentulPageQuery} from "../types/__generated__/ContentulPageQuery";
import Layout from "../container/Layout";
import Sections from "../components/v2/Sections.jsx";

type PageProps = {|
  +data: ContentulPageQuery,
|};

const PageAidTrackerCampaign = ({data}: PageProps): Element<any> => {
  const {contentfulPage} = data;

  return (
    <Layout
      slug={`aid-tracker/${contentfulPage?.slug || ""}`}
      showAnnouncementBanner={contentfulPage?.showAnnouncementBanner}
      headerInfo={contentfulPage?.headerInfo}
    >
      <Sections sections={contentfulPage?.sections || []} />
    </Layout>
  );
};

export default PageAidTrackerCampaign;

export const query: any = graphql`
  query ContentulPageAidTrackerCampaignQuery($slug: String!, $locale: String!) {
    contentfulPage(slug: {eq: $slug}, node_locale: {eq: $locale}) {
      __typename
      internalTitle
      slug
      navigationName
      isReadyForProduction
      showAnnouncementBanner
      headerInfo {
        ...ContentfulHeaderInfoFragment
      }
      sections {
        __typename
        ... on ContentfulHero {
          ...ContentfulHeroFragment
        }
        ... on ContentfulMultiColumn {
          ...ContentfulMultiColumnFragment
        }
        ... on ContentfulPartners {
          ...ContentfulPartnersFragment
        }
        ... on ContentfulQuote {
          ...ContentfulQuoteFragment
        }
        ... on ContentfulSplitModule {
          ...ContentfulSplitModuleFragment
        }
        ... on ContentfulAidTrackerMap {
          ...ContentfulAidTrackerMapFragment
        }
        ... on ContentfulGetStartedModule {
          ...ContentfulGetStartedModuleFragment
        }
        ... on ContentfulLazyLoadMultiColumn {
          ...ContentfulLazyLoadMultiColumnFragment
        }
        ... on ContentfulHeadlineFullBleedImageStack {
          ...ContentfulHeadlineFullBleedImageStackFragment
        }
      }
    }
  }
`;
